<template>
  <div class="container">
    <div class="main">
      <div class="ct-title">数据概览</div>
      <ul class="header">
        <li>
          <div class="title">收益</div>
          <div class="hd-content">
            <img src="../../assets/home/hm_01.png" alt="">
            <div class="hd-ft">
              <p>{{accountInfo.totalPayAmount}}</p>
              <span>总收益</span>
            </div>
            <div class="hd-ft hd-fr">
              <p>{{accountInfo.dayPayAmount||0.00}}</p>
              <span>今日收益</span>
            </div>
          </div>
        </li>
        <li>
          <div class="title">交易笔数</div>
          <div class="hd-content">
            <img src="../../assets/home/hm_02.png" alt="">
            <div class="hd-ft">
              <p>{{accountInfo.totalPayNum||0}}</p>
              <span>总支付笔数</span>
            </div>
            <div class="hd-ft hd-fr">
              <p>{{accountInfo.dayPayNum||0}}</p>
              <span>今日笔数</span>
            </div>
          </div>
        </li>
        <li>
          <div class="title">门店</div>
          <div class="hd-content">
            <img src="../../assets/home/hm_03.png" alt="">
            <div class="hd-ft">
              <p>{{accountInfo.merchantNum||0}}</p>
              <span>总门店总数</span>
            </div>
          </div>
        </li>
        <li>
          <div class="title">业务员</div>
          <div class="hd-content">
            <img src="../../assets/home/hm_04.png" alt="">
            <div class="hd-ft">
              <p>{{accountInfo.salesmanNum||0}}</p>
              <span>业务员总数</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="content">
      <div class="ct-title">流程管理</div>
      <div class="display-content">
        <div class="img">
          <img src="../../assets/content_01.png" alt="">
          <!-- <div class="line"></div> -->
        </div>
        <!-- <div class="tree">
          <div>
            <div id="treeMode" :style="{width: '1152px', height: '600px'}"></div>
          </div>
        </div> -->
        <div style="width:1110px">
          <div class="tree">
            <TreeRight></TreeRight>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TreeRight from "./components/tree"
import {
  getEnterpriseStatistics,
  getFlow
} from '@/api/api'
export default {
  components:{
    TreeRight
  },
  data () {
    return {
      list: {
        "name": "门店",
        "value": '1',
        "id":'123123',
        "children": [
            {
                "name": "data",
                "value": '1',
                "id":'123123',
                "children": [
                    {
                        "name": "converters",
                        "children": [
                            {"name": "Converters", "value": 721},
                            {"name": "DelimitedTextConverter", "value": 4294}
                        ]
                    },
                    {
                        "name": "DataUtil",
                        "value": 3322
                    }
                ]
            },
            {
                "name": "display",
                "children": [
                    {"name": "DirtySprite", "value": 8833},
                    {"name": "LineSprite", "value": 1732},
                    {"name": "RectSprite", "value": 3623}
                ]
            },
            {
                "name": "flex",
                "children": [
                    {"name": "FlareVis", "value": 4116}
                ]
            },
            {
                "name": "query",
                "children": [
                    {"name": "AggregateExpression", "value": 1616},
                    {"name": "And", "value": 1027},
                    {"name": "Arithmetic", "value": 3891},
                    {"name": "DateUtil", "value": 4141},
                    {
                        "name": "methods",
                        "children": [
                            {"name": "add", "value": 593},
                            {"name": "select", "value": 296},
                            {"name": "stddev", "value": 363},
                            {"name": "where", "value": 299},
                            {"name": "xor", "value": 354},
                            {"name": "x_x", "value": 264}
                        ]
                    },
                    {"name": "Minimum", "value": 843},
                    {"name": "Range", "value": 1594},
                    {"name": "StringUtil", "value": 4130},
                    {"name": "Sum", "value": 791},
                ]
            },
            {
                "name": "scale",
                "children": [
                    {"name": "IScaleMap", "value": 2105},
                    {"name": "Scale", "value": 4268},
                    {"name": "ScaleType", "value": 1821},
                    {"name": "TimeScale", "value": 5833}
                ]
            }
        ]
      },
      accountInfo: null
    }
  },
  created () {
    this.getCount()
  },
  mounted(){
    // this.getTreeData();
  },
  methods: {
    // 获取数据统计
    getCount () {
      getEnterpriseStatistics().then(res=>{
        if (res.success) {
          this.accountInfo = res.result
        }
      })
    },
    // 获取树结构
    getTreeData () {
      getFlow().then(res=>{
        if (res.success) {
          this.list.children = res.result
          this.drawLine()
        }
      })
    },
    // 树状图
    drawLine () {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById('treeMode'))
        // 绘制图表
        myChart.setOption({
          tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
          },
          series:[
            {
              type: 'tree',
              id: 0,
              name: 'tree1',
              data: [this.list],
              top: '10%',
              left: '8%',
              bottom: '22%',
              right: '20%',
              symbolSize: 7,
              edgeShape: 'polyline',
              edgeForkPosition: '63%',
              initialTreeDepth: 3,
              lineStyle: {
                width: 2
              },
              label: {
                backgroundColor: '#fff',
                position: 'left',
                verticalAlign: 'middle',
                align: 'right'
              },
              leaves: {
                label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left'
                }
              },
              emphasis: {
                focus: 'descendant'
              },
              expandAndCollapse: true,
              animationDuration: 550,
              animationDurationUpdate: 750
            }
          ]
        });
        // myChart.on('click', function (params) {
        //   console.log(params);
        // });
    }
  }
}
</script>
<style scoped lang="less">
.container{
  // background: #FFFFFF;
  border-radius: 4px;
  min-height: 935px;
  padding: 40px;
}
.display-content{
  display: flex;
  padding: 0 0 64px 55px;
  align-items: center;
  position: relative;
  .line{
    width: 90px;
    height: 1px;
    background: #959EA6;
    position: absolute;
    top: 375px;
    right: -113px;
  }
}
.main{
  background: #FFFFFF;
  border: 1px solid #E3E4E6;
  opacity: 1;
  border-radius: 4px;
  width: 100%;
  .ct-title{
    color: #737373;
    font-size: 16px;
    border-bottom: 1px solid #F0F0F0;
    height: 52px;
    line-height: 52px;
    padding-left: 18px;
  }
}
.header{
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 15px 0 27px 0;
  li{
    padding: 20px;
    background: #F7F9FA;
    border-radius: 2px;
    font-size: 14px;
    color: #737373;
    .title{
      color: #333333;
    }
    .hd-content{
      display: flex;
      align-items: center;
      margin-top: 9px;
      color: #333333;
      img{
        width: 50px;
        height: 50px;
      }
      p{
        font-size: 24px;
        margin-bottom: 3px;
      }
      span{
        color: #737373;
        font-size: 12px;
      }
      .hd-ft{
        margin: 0 100px 0 30px;
      }
      .hd-fr{
        margin: 0 40px 0 0px;
      }
    }
  }
}
.content{
  margin-top: 25px;
  background: #FFFFFF;
  border: 1px solid #E3E4E6;
  opacity: 1;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  .ct-title{
    color: #737373;
    font-size: 16px;
    border-bottom: 1px solid #F0F0F0;
    height: 52px;
    line-height: 52px;
    padding-left: 18px;
  }
  .img{
    width: 143px;
    // margin: 0 auto;
    margin-top: 58px;
    height: 621px;
    position: relative;
    img{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 20;
    }
  }
  .tree{
    width: 100%;
    margin: 240px 0 50px 0;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 12px;
    // position: absolute;
    // top: 305px;
    // left: 100px;
  }
}
</style>