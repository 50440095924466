<template>
  <div class="tree-container">
    <div class="line"></div>
    <ul class="tree-first" :class="list.length>1?'is-one':''">
      <li v-for="(firstItem,firstIndex) in list" :key="firstIndex">
        <span class="line">
          <span class="hide-line-one-top" v-if="firstIndex===0"></span>
          <span class="hide-line-one-bottom" v-if="firstIndex===list.length-1"></span>
        </span>
        <span @click="selectIt(firstItem)" class="name">
          {{firstItem.name}}
        </span>
        <span class="line" v-if="firstItem.collapse&&firstItem.children&&firstItem.children.length>0">
        </span>
        <ul class="tree-second" :class="firstItem.children.length>1?'is-one':''" id="strrSecond" v-if="firstItem.collapse&&firstItem.children&&firstItem.children.length>0">
          <li v-for="(secondItem,secondIndex) in firstItem.children" :key="secondIndex">
            <span class="line">
              <span class="hide-line-second-top" v-if="secondIndex===0"></span>
              <span class="hide-line-second-bottom" v-if="secondIndex===firstItem.children.length-1"></span>
            </span>
            <span @click="selectIt(secondItem)" class="name">
              {{secondItem.name}}（{{secondItem.statusText}}）
            </span>
            <span class="line" v-if="secondItem.collapse&&secondItem.children&&secondItem.children.length>0"></span>
            <ul class="tree-three" :class="secondItem.children.length>1?'is-one':''" v-if="secondItem.collapse&&secondItem.children&&secondItem.children.length>0">
              <li v-for="(threeItem,threeIndex) in secondItem.children" :key="threeIndex">
                <span class="line">
                  <span class="hide-line-three-top" v-if="threeIndex===0"></span>
                  <span class="hide-line-three-bottom" v-if="threeIndex===secondItem.children.length-1"></span>
                </span>
                <span @click="selectIt(threeItem)" class="name">
                  <img :src="threeItem.headUrl" style="width:20px;border-radius:50%" alt=""> {{threeItem.name}}
                </span>
                <span class="line" v-if="threeItem.collapse&&threeItem.children&&threeItem.children.length>0"></span>
                <ul class="tree-four" :class="threeItem.children.length>1?'is-one':''" v-if="threeItem.collapse&&threeItem.children&&threeItem.children.length>0">
                  <li v-for="(fourItem,fourIndex) in threeItem.children" :key="fourIndex">
                    <span class="line">
                      <span class="hide-line-four-top" v-if="fourIndex===0"></span>
                      <span class="hide-line-four-bottom" v-if="fourIndex===threeItem.children.length-1"></span>
                    </span>
                    <span @click="selectIt(fourItem)" class="name">
                      消费者付款<span style="margin-left:30px">{{fourItem.payAmount}}</span><br>
                      业务员提成<span style="margin-left:30px">{{fourItem.serviceFeeAmount}}</span><br>
                      {{fourItem.payTime}}<br>
                    </span>
                    <span class="line" v-if="fourItem.collapse&&fourItem.children&&fourItem.children.length>0"></span>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import {
  getFlowPaint
} from '@/api/api'
export default {
  data () {
    return {
      list:[],
      secondHeight: '',
      lists:[
        {
          name: '123123',
          collapse: false
        },
        {
          name: '123123',
          collapse: false
        },
        {
          name: '123123',
          collapse: false
        },
        {
          name: '123123',
          collapse: false
        },
        {
          name: '123123',
          collapse: false
        },
      ]
    }
  },
  watch:{
    list: {
      handler(newV,oldV) {
        this.list = newV
      },
      deep: true
    }
  },
  created () {
    getFlowPaint({level:1}).then(res => {
      if (res.success) {
        this.list = res.result
      }
    })
  },
  methods: {
    selectIt (item) {
      item.collapse = !item.collapse
      if (item.level===2) {
        this.list.forEach(list=>{
          if (item.id!==list.id) {
            list.collapse=false
          }
          if (item.id===list.id) {
            if (item.collapse&&!item.children) {
              getFlowPaint({level:2,merchantId:item.id}).then(res => {
                if (res.success) {
                  list.children = res.result
                  this.$forceUpdate();
                  if (res.result.length===0) {
                    this.$message.warning('没有更多啦！')
                  }
                }
              })
            }
          }
        })
      }
      if (item.level===3) {
        this.list.forEach(list=>{
          if (list.children&&list.collapse) {
            list.children.forEach(list1=>{
              if (item.id!==list1.id) {
                list1.collapse=false
              }
              if (item.id===list1.id) {
                if (item.collapse) {
                  getFlowPaint({level:3,merchantId:list.id,serviceUserId:item.serviceUserId,taskId:item.taskId}).then(res => {
                    if (res.success) {
                      list1.children = res.result
                      this.$forceUpdate();
                      if (res.result.length===0) {
                        this.$message.warning('没有更多啦！')
                      }
                    }
                  })
                }
              }
            })
          }
        })
      }
      if (item.level===4) {
        this.list.forEach(list=>{
          if (list.children&&list.collapse) {
            list.children.forEach(list1=>{
              if (list1.children&&list1.collapse) {
                list1.children.forEach(list2=>{
                  if (item.id!==list2.id) {
                    list2.collapse=false
                  }
                  if (item.id===list2.id) {
                    if (list2.collapse&&list2.collapse) {
                      getFlowPaint({level:4,merchantId:list.id,serviceUserId:list1.serviceUserId,taskId:list1.taskId,userId:item.id}).then(res => {
                        if (res.success) {
                          list2.children = res.result
                          this.$forceUpdate();
                          if (res.result.length===0) {
                            this.$message.warning('没有更多啦！')
                          }
                        }
                      })
                    }
                  }
                })
              }
            })
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="less">
.tree-container{
  display: flex;
  align-items: center;
  .line{
    width: 100px;
    height: 1px;
    background: #959EA6;
  }
}
.tree-first{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    .name{
      height: 41px;
      padding: 10px 27px;
      background:  #F0F0F0;
      border-radius: 4px;
      color: #737373;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
    }
    .line{
      width: 50px;
      height: 1px;
      background: #959EA6;
      position:relative;
      .hide-line-one-top{
        position: absolute;
        left: -1px;
        top: -400px;
        width: 1px;
        height: 400px;
        background: #FFF;
        z-index: 10;
      }
      .hide-line-one-bottom{
        position: absolute;
        left: -1px;
        bottom: -80px;
        width: 1px;
        height: 80px;
        background: #FFF;
        z-index: 10;
      }
    }
  }
}
.tree-second{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    .name{
      height: 41px;
      padding: 10px 27px;
      background: #F0F0F0;
      border-radius: 4px;
      color: #737373;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
    }
    .line{
      width: 50px;
      height: 1px;
      background: #959EA6;
      position: relative;
      .hide-line-second-top{
        position: absolute;
        left: -1px;
        top: -300px;
        width: 1px;
        height: 300px;
        background: #FFFFFF;
        z-index: 10;
      }
      .hide-line-second-bottom{
        position: absolute;
        left: -1px;
        bottom: -200px;
        width: 1px;
        height: 200px;
        background: #FFF;
        z-index: 10;
      }
    }
  }
}
.tree-three{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    .name{
      height: 41px;
      padding: 10px 27px;
      background:#F0F0F0;
      border-radius: 4px;
      color: #737373;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
    }
    .line{
      width: 50px;
      height: 1px;
      background: #959EA6;
      position: relative;
      .hide-line-three-top{
        position: absolute;
        left: -1px;
        top: -200px;
        width: 1px;
        height: 200px;
        background: #FFF;
        z-index: 10;
      }
      .hide-line-three-bottom{
        position: absolute;
        left: -1px;
        bottom: -30px;
        width: 1px;
        height: 30px;
        background: #FFF;
        z-index: 10;
      }
    }
  }
}
.tree-four{
  padding: 0;
  margin: 0;
  list-style: none;
  li{
    margin: 10px 0;
    display: flex;
    align-items: center;
    position: relative;
    .name{
      height: 82px;
      display: inline-block;
      padding: 10px 27px;
      background: #F0F0F0;
      border-radius: 4px;
      color:#737373;
      position: relative;
      cursor: pointer;
      white-space: nowrap;
    }
    .line{
      width: 50px;
      height: 1px;
      background: #959EA6;
      position: relative;
      .hide-line-four-top{
        position: absolute;
        left: -1px;
        top: -50px;
        width: 1px;
        height: 50px;
        background: #FFF;
        z-index: 10;
      }
      .hide-line-four-bottom{
        position: absolute;
        left: -1px;
        bottom: -50px;
        width: 1px;
        height: 50px;
        background: #FFF;
        z-index: 10;
      }
    }
  }
}
.is-one{
  border-left: 1px solid #959EA6;
}
</style>